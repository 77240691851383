export const getMotivosCancelacion = async () => {
  return [
    {
      id: 1,
      name: "Dificultad para organizar el tiempo",
      value: "Dificultad para organizar el tiempo",
      customDesign: true,
      textButtonRedirect: "Ir a quick workouts"
    },
    {
      id: 2,
      name: "Razones económicas",
      value: "Razones económicas",
      customDesign: true,
      textButtonRedirect: "Obtener descuento",
    },
    {
      id: 3,
      name: "Por instrucción médica",
      value: "Por instrucción médica",
      customDesign: true,
      textButtonRedirect: "Embarazo en reposo",
      isValid: (programCode) => programCode?.toLowerCase().includes("embarazo")
    },
    {
      id: 4,
      name: "Regreso al gym (otro tipo de ejercicio)",
      value: "Regreso al gym (otro tipo de ejercicio)",
      customDesign: true,
      textButtonRedirect: "Ver programas",
      isValid: (programCode) =>
        programCode?.toLowerCase().includes("embarazo") ||
        programCode?.toLowerCase().includes("posparto"),
    },
    {
      id: 5,
      name: "No era lo que buscaba",
      value: "No era lo que buscaba",
      customDesign: true,
      textButtonRedirect: "Obtener descuento",
    },
    {
      id: 6,
      name: "Problemas con el app",
      value: "Problemas con el app",
      customDesign: true
    },
    {
      id: 7,
      name: "Otro",
      value: "Otro",
      customDesign: true
    },
  ];
}