import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player/lazy';
import { Button } from 'primereact/button';
import IconoIr from "../../assets/icons/flecha-icon.svg";
// import { usePromocion } from "../../components/PromocionProvider";

const CarouselProgramasTemplate = (item) => {
  // const { state } = usePromocion();
  const navigate = useNavigate();

  const styleBackgroundImage = useMemo(() => item?.image ? ({ backgroundImage: `url(${item?.image})` }) : undefined, [item?.image]);

  const _onClick = useCallback(() => {
    if (item?.url_external) {
      const win = window?.open(item?.url, '_blank');
      if (win?.focus) win.focus();
    } else {
      navigate(item?.url);
    }
  }, [item?.url_external, item?.url, navigate]);

  return (
    <div className={`lg:aspect-ratio-full w-full bg-gray-400 bg-cover bg-center bg-no-repeat pb-16 h-full ${item?.classNameBg || ""}`} style={styleBackgroundImage}>
      {item?.video && (
        <div className="absolute h-full w-full z-1">
          <ReactPlayer
            className="!h-full !w-full cover-video"
            url={item?.video}
            playing={true}
            loop={true}
            controls={false}
            muted={true}
            playsinline={true}
          />
        </div>
      )}
      <div className="relative text-white w-11/12 mx-auto z-10">
        <div className="md:max-w-sm" style={{ paddingTop: "4em", paddingBottom: "4em" }}>
          {!item?.hideTexts && (
            <div className="pt-[5rem]">
              <h1 className={`mb-1 font-bold text-3xl md:text-5xl ${item?.classNameText || ""}`}>{item?.title}</h1>
              <h2 className={`mt-0 mb-7 text-3xl md:text-5xl ${item?.classNameText || ""}`}>{item?.subtitle}</h2>
              <p className={`font-medium mt-0 mb-10 ${item?.classNameText || ""}`}>{item?.description}</p>
            </div>
          )}
          {!(item?.hideButton) && (
            <div className="border-Orchid" style={{ marginBottom: "3em" }}>
              {
                item?.url_anchor ?
                  <a href={item?.url} className="font-bold border-transparent p-button p-button-rounded bg-Orchid hover:bg-Orchid py-2 px-4" >
                    {item?.buttonText || "¡Comienza ahora!"}
                    <span className="d-block ml-3">
                      <img src={IconoIr} alt="Ir" style={{ height: 18, width: 18 }} />
                    </span>
                  </a>
                  :
                  <Button className="flex items-center font-bold border-transparent p-button p-button-rounded bg-Orchid hover:bg-Orchid py-2 px-4" onClick={_onClick}>
                    {item?.buttonText || "¡Comienza ahora!"}
                    <span className="d-block ml-3">
                      <img src={IconoIr} alt="Ir" style={{ height: 18, width: 18 }} />
                    </span>
                  </Button>
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CarouselProgramasTemplate;