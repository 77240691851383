import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import dayjs from 'dayjs';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { ReactComponent as BtnClose } from '../../assets/icons/btn-close2.svg';
import { ButtonCustom, ButtonPrimary } from '../../components/Button';
import Grid from '../../components/Grid';
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Loading from '../../components/Loading';
import { useCustomState } from '../../hooks/useCustomState';
import { getNumericZero, isEmpty } from '../../utils';
import { dayjs_custom } from '../../utils/dayjs_custom';
import { getMotivosCancelacion } from '../../misc/getMotivosCancelacion';
import { setUser } from '../../redux/reducers/authSlice';
import { getApiKeys } from '../../api/client/config';
import { getProgramas } from '../../api/client/programas';
import { getClienteSecreto, verificar_tarjeta, cancelar_verificar_tarjeta, crear_suscripcion_paypal } from '../../api/client/pagos';
import { actualizar_metodo_pago, cancelSubscription, getSubscription, resumeSubscription, updateSubscription } from '../../api/client/mi_suscripcion';
import { getPerfil } from '../../api/client/perfil';
import { optionsToast } from '../../config/toast';
import { setSubscription } from '../../redux/reducers/subscriptionSlice';
import parseErrorMessage from '../../utils/parseErrorMessage';
import updateUserStore from '../../utils/updateUserStore';
import generarCodigoFactura from '../../utils/generarCodigoFactura';
import CustomComponentCancelarSuscripcion, { handleActionById } from '../../components/Modal/CancelarSuscripcionComponents';

require('dayjs/locale/es');
dayjs.locale("es");

let stripePromise = null, apiKeysRemote = {};

const MiSuscripcion = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams.entries());
  const paypal_success_payment = searchParams.get("paypal-success");
  const select_program_param = searchParams.get("select_program");
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    saving: {
      change_suscription: false,
      cancel_suscription: false,
      confirm_suscription: false,
    },
    viewSelected: null,
    suscripcion_seleccionada: {
      id: null,
      idProgram: null,
      code: null,
      status: null,
      status_external: null,
      tipo_programa: null,
      nombre: null,
      plan: null,
      precio: null,
      fecha_inicio: null,
      fecha_cobro: null,
      card: null,
      paypal: null,
      paymentType: null,
    },
    motivos_cancelacion: [],
    motivo_seleccionado: null,
    opinion_cancelacion: null,
    confirmDialogVisible: false,
  });

  useEffect(() => {
    let getLocalSubscriptionID;
    try {
      getLocalSubscriptionID = JSON.parse(window.localStorage.getItem("subscriptionID-paypal"));
    } catch (error) { }
    if (paypal_success_payment === "true" || !isEmpty(getLocalSubscriptionID?.id)) {
      confirmarSuscripcion(getLocalSubscriptionID);
    }
    // eslint-disable-next-line
  }, [paypal_success_payment]);

  useEffect(() => {
    if (user?.userID) loadData(user);
    // eslint-disable-next-line
  }, [user?.userID]);

  useEffect(() => {
    updateUserStore({ id: user?.id, email: user?.email }, dispatch);
    // eslint-disable-next-line
  }, [user?.id, user?.email]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      viewSelected: select_program_param === "true" ? "actualizarSuscripcion" : state.viewSelected
    }));
    // eslint-disable-next-line
  }, [select_program_param]);

  const motivo_seleccionado = useMemo(() => {
    try {
      if (state.motivo_seleccionado) {
        const motivos_cancelacion = state.motivos_cancelacion || [];
        const motivo_seleccionado = motivos_cancelacion?.length > 0 ? motivos_cancelacion.find(motivo => motivo.value === state.motivo_seleccionado) : null;
        return motivo_seleccionado;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }, [state.motivos_cancelacion, state.motivo_seleccionado]);

  const renameTextButtonPrograma = useMemo(() => {
    if (state?.suscripcion_seleccionada?.Program?.code === "fitmom-embarazo") return motivo_seleccionado?.textButtonRedirect?.replace("$Programa", "Posparto");
    if (state?.suscripcion_seleccionada?.Program?.code === "fitmom-posparto") return motivo_seleccionado?.textButtonRedirect?.replace("$Programa", "Strength");

    return motivo_seleccionado?.textButtonRedirect;
  }, [motivo_seleccionado?.textButtonRedirect, state?.suscripcion_seleccionada?.Program?.code]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const loadData = useCallback(async (user) => {
    setState({ loading: true, suscripcion_seleccionada: null });
    const suscripcion = await getSubscription({
      userID: user?.userID,
    });
    const fecha_inicio = suscripcion?.data?.subscriptionStartDate;
    const fecha_cobro = suscripcion?.data?.nextBillingDate;
    const fecha_expiracion = suscripcion?.data?.isInternal ? suscripcion?.data?.dateExpirationEnd : (suscripcion?.data?.subscriptionCancelDate || suscripcion?.data?.nextBillingDate);

    setState({
      loading: false,
      suscripcion_seleccionada: {
        ...suscripcion?.data,
        id: suscripcion?.data?.id,
        idProgram: suscripcion?.data?.Program?.id,
        idSubscription: suscripcion?.data?.subscriptionTransaction,
        code: suscripcion?.data?.Program?.code,
        status: suscripcion?.data?.status,
        status_to_expire: suscripcion?.data?.status_to_expire,
        isTrial: suscripcion?.data?.isTrial,
        tipo_programa: "Programa de entrenamiento",
        nombre: suscripcion?.data?.Program?.name,
        plan: suscripcion?.data?.typePlan,
        precio: suscripcion?.data?.billingAmount,
        fecha_inicio: fecha_inicio && dayjs_custom(fecha_inicio, { isUTC: true }).format("YYYY-MM-DD"),
        fecha_cobro: fecha_cobro && dayjs_custom(fecha_cobro, { isUTC: true }).format("YYYY-MM-DD"),
        fecha_expiracion: (fecha_expiracion) && dayjs_custom(fecha_expiracion, { isUTC: true }).format("YYYY-MM-DD"),
        card: suscripcion?.data?.paymentType === "Stripe" ? {
          last_4: suscripcion?.data?.cardLastNumber,
          exp_month: suscripcion?.data?.expirationMonth,
          exp_year: suscripcion?.data?.expirationYear,
        } : null,
        paypal: suscripcion?.data?.paymentType === "PayPal" ? {
          email: (suscripcion?.data?.paypalSubscriberID || suscripcion?.data?.User?.Client?.email || "Desconocido")
        } : null,
        paymentType: suscripcion?.data?.paymentType,
      },
      motivos_cancelacion: await getMotivosCancelacion()
    });
  }, [setState]);

  const loadUserInfo = useCallback(async (_user) => {
    _user = _user || user;
    if (_user) getPerfil({ id: _user?.id, email: _user?.email }).then(response => (response?.status === true && response?.data) && dispatch(setUser(response?.data)));
  }, [user, dispatch]);

  const confirmarSuscripcion = useCallback(async (getLocalSubscriptionID = null) => {
    try {
      const response = await updateSubscription({
        userID: user?.userID,
        programID: queryParams?.programID || getLocalSubscriptionID?.programID,
        cycleType: queryParams?.cycleType || getLocalSubscriptionID?.cycleType,
        subscription_id: queryParams?.subscription_id || getLocalSubscriptionID?.id,
        invoiceCode: queryParams?.invoiceCode || getLocalSubscriptionID?.invoiceCode,
      });

      if (response?.status) {
        message('Suscripción actualizada', '¡La suscripción se ha actualizado exitosamente!');

        try {
          window.localStorage.removeItem("subscriptionID-paypal");
          await loadData({ userID: user?.userID });
        } catch (error) { }
        try {
          await updateUserStore({ id: user?.id, email: user?.email }, dispatch);
        } catch (error) { }
      }
      navigate("/mi-cuenta/mi-suscripcion");
    } catch (error) {
      const errorMessage = parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al confirmar la suscripción. Por favor, inténtalo de nuevo más tarde.';
      message('Ocurrió un problema', errorMessage);
    }
  }, [user?.userID, user?.id, user?.email, queryParams, dispatch, loadData, navigate]);

  const acceptDialog = useCallback(async () => {
    try {
      if (isEmpty(state.motivo_seleccionado)) {
        return message('El motivo es obligatorio', 'Selecciona un motivo de cancelación para continuar');
      }

      setState({ cancel_suscription: true }, 'saving');

      const response = await cancelSubscription({
        id: state.suscripcion_seleccionada?.id,
        reason: state.motivo_seleccionado,
        opinion: state.opinion_cancelacion,
        type: "pause",
      });

      if (response?.status === true) {
        setState({ confirmDialogVisible: false });
        message('Suscripción cancelada', 'Se ha cancelado la suscripción éxitosamente');
        loadData(user);
        loadUserInfo(user);
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al cancelar la suscripción. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al cancelar la suscripción. Por favor, inténtalo de nuevo más tarde.');
    }
    setState({ cancel_suscription: false }, 'saving');
  }, [user, state.suscripcion_seleccionada?.id, state.motivo_seleccionado, state.opinion_cancelacion, setState, loadData, loadUserInfo]);

  const reanudar_suscripcion = useCallback(async () => {
    if (state.suscripcion_seleccionada?.isInternal && state.suscripcion_seleccionada?.status === "expired") {
      dispatch(setSubscription({
        data: {
          id: state.suscripcion_seleccionada?.idProgram,
          tipo_programa: state.suscripcion_seleccionada?.tipo_programa,
          code: state.suscripcion_seleccionada?.code,
          nombre: state.suscripcion_seleccionada?.nombre,
          plan: (state.suscripcion_seleccionada?.plan || "mensual")?.toLowerCase(),
          precio: state.suscripcion_seleccionada?.precio,
        }
      }));
      return navigate("/carrito");
    }

    try {
      setState({ cancel_suscription: true }, 'saving');

      if (state.suscripcion_seleccionada?.paymentType === 'PayPal') {
        if (state.suscripcion_seleccionada?.status === 'paused' || state.suscripcion_seleccionada?.status_to_expire === 'Por expirar') {
          const response = await resumeSubscription({
            id: state.suscripcion_seleccionada?.id,
            type: 'resume'
          });

          if (response?.status === true) {
            setState({ viewSelected: null });
            message('Suscripción reanudada', 'Se ha reanudado la suscripción éxitosamente');
            await loadData(user);
            loadUserInfo(user);
          } else {
            message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al reanudar la suscripción. Por favor, inténtalo de nuevo más tarde.');
          }
        } else {
          dispatch(setSubscription({
            data: {
              id: state.suscripcion_seleccionada?.idProgram,
              tipo_programa: state.suscripcion_seleccionada?.tipo_programa,
              code: state.suscripcion_seleccionada?.code,
              nombre: state.suscripcion_seleccionada?.nombre,
              plan: state.suscripcion_seleccionada?.plan?.toLowerCase(),
              precio: state.suscripcion_seleccionada?.precio,
            }
          }));
          navigate("/carrito?method_payment=paypal");
        }
      } else {
        const response = await resumeSubscription({
          id: state.suscripcion_seleccionada?.id,
        });

        if (response?.status === true) {
          setState({ viewSelected: null });
          message('Suscripción reanudada', 'Se ha reanudado la suscripción éxitosamente');
          await loadData(user);
          loadUserInfo(user);
        } else {
          message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al reanudar la suscripción. Por favor, inténtalo de nuevo más tarde.');
        }
      }
    } catch (error) {
      const _message = parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al reanudar la suscripción. Por favor, inténtalo de nuevo más tarde.';

      if (_message.indexOf("La suscripción ha sido cancelada y se requiere comprar de nuevo") >= 0) {
        dispatch(setSubscription({
          data: {
            id: state.suscripcion_seleccionada?.idProgram,
            tipo_programa: state.suscripcion_seleccionada?.tipo_programa,
            code: state.suscripcion_seleccionada?.code,
            nombre: state.suscripcion_seleccionada?.nombre,
            plan: state.suscripcion_seleccionada?.plan?.toLowerCase(),
            precio: state.suscripcion_seleccionada?.precio,
          }
        }));
        return navigate("/carrito?method_payment=paypal");
      } else {
        message('Ocurrió un problema', _message);
      }
    }
    setState({ cancel_suscription: false }, 'saving');
  }, [user, state.suscripcion_seleccionada, setState, loadData, loadUserInfo, dispatch, navigate]);

  const onFinishUpdate = useCallback(async (_user) => {
    await loadData((_user || user));
    setState({ viewSelected: null });
  }, [user, setState, loadData]);

  const getPortadaPrograma = useCallback(() => {
    let image;
    switch (state.suscripcion_seleccionada.code) {
      case "fitmom-embarazo":
        image = require("../../assets/images/programas/header-embarazo.jpg");
        break;
      case "fitmom-posparto":
        image = require("../../assets/images/programas/header-posparto.jpg");
        break;
      case "fitmom-strength":
        image = require("../../assets/images/programas/header-strength.jpg");
        break;

      default:
        break;
    }

    return image;
  }, [state.suscripcion_seleccionada]);

  return (
    <>
      <SEO
        title="Mi Suscripción"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />
      <Toast ref={toast} />
      <ConfirmDialog
        visible={state.confirmDialogVisible || false}
        className="mx-4"
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
        pt={{ message: { className: "!ml-0 w-full" } }}
        message={() => (
          <>
            <div className='flex flex-col gap-4 mt-12 w-full'>
              <div className='flex -mt-6 -mb-2 justify-end w-full'>
                <button className='c-pointer h-8 w-8 -mr-3 opacity-80 no-tap-highlight' onClick={() => setState({ confirmDialogVisible: false })}>
                  <BtnClose height={16} width={16} />
                </button>
              </div>
              <div>
                <p className='font-golos-medium text-center text-Crayola text-2xl md:text-4xl mb-2'>¿Nos dejas?</p>
                <p className='font-golos text-center text-sm mb-6'><span className='font-golos-semibold'>Antes de marcharte,</span> queremos escucharte.</p>
                <p className='font-golos text-center text-sm pt-5'>¿Por qué motivo cancelas tu suscripción?</p>
              </div>
              <div className="card flex flex-col gap-2 justify-center">
                <Dropdown
                  value={state.motivo_seleccionado}
                  onChange={(e) => setState({ motivo_seleccionado: e.value })}
                  options={state.motivos_cancelacion}
                  optionLabel="name"
                  placeholder="Selecciona un motivo"
                  className="bg-Magnolia border-0 w-full text-sm text-left"
                  emptyMessage="No se encontraron resultados"
                />
                <textarea
                  className='bg-Magnolia border-0 border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 rounded-lg min-h-[140px] w-full text-sm text-gray-900 text-left transition-all'
                  placeholder="Escribe tu opinión (Es opcional)"
                  onChange={event => setState({ opinion_cancelacion: event.target.value })}
                />
              </div>
              {(motivo_seleccionado?.customDesign &&
                (!motivo_seleccionado?.isValid ||
                  motivo_seleccionado?.isValid(state?.suscripcion_seleccionada?.Program?.code)
                )
              ) && (
                  <div className='mt-3'>
                    <CustomComponentCancelarSuscripcion id={motivo_seleccionado?.id} programCode={state.suscripcion_seleccionada?.Program?.code} />
                  </div>
                )}
            </div>
          </>
        )}
        showHeader={false}
        closable={false}
        blockScroll={true}
        contentClassName="text-center ml-0 !rounded-t-xl -mt-[1px] !pb-2"
        acceptLabel="Cancelar suscripción"
        footer={(options) => (
          <div className='flex items-center justify-center gap-1.5 mt-6 !rounded-b-xl'>
            <button
              className='font-golos-medium text-sm text-dark border border-Magnolia bg-Magnolia rounded-full !m-0 px-6 py-2 active:scale-95 transition-all no-tap-highlight'
              onClick={acceptDialog || options.accept}
            >
              {state.saving.cancel_suscription ? <div className='flex items-center'><ProgressSpinner style={{ width: 24, height: 24 }} /></div> : options.acceptLabel}
            </button>
            {(renameTextButtonPrograma &&
              !isEmpty(renameTextButtonPrograma) &&
              (!motivo_seleccionado?.isValid ||
                motivo_seleccionado?.isValid(state?.suscripcion_seleccionada?.Program?.code)
              )
            ) && (
                <button
                  className='font-golos-semibold text-sm text-white border border-Orchid bg-Orchid rounded-full !m-0 px-6 py-2 active:scale-95 transition-all no-tap-highlight'
                  onClick={() => handleActionById(
                    motivo_seleccionado?.id,
                    navigate,
                    null,
                    { beforeAction: () => setState({ confirmDialogVisible: false }) }
                  )}
                >
                  {renameTextButtonPrograma}
                </button>
              )}
          </div>
        )}
        accept={acceptDialog}
        onHide={() => setState({ confirmDialogVisible: false })}
        style={{ width: '440px', maxWidth: "95%" }}
      />

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                {paypal_success_payment === "true" ? (
                  <Loading textLoading={"Estamos procesando tu suscripción, por favor espere"} />
                ) : (
                  <>
                    {state.loading ? (
                      <Loading />
                    ) : (
                      <>
                        {(isEmpty(state.suscripcion_seleccionada?.id)) ? (
                          <div className='flex flex-col items-center justify-center gap-6'>
                            <div>
                              <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                              <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                            </div>
                          </div>
                        ) : (
                          <>
                            {state?.viewSelected === "actualizarSuscripcion" ? (
                              <ActualizarSuscripcion
                                suscripcion_seleccionada={state.suscripcion_seleccionada}
                                idSuscripcion={state.suscripcion_seleccionada?.id}
                                onFinishUpdate={onFinishUpdate}
                                onCancel={() => setState({ viewSelected: null })}
                              />
                            ) : state?.viewSelected === "actualizarMetodoPago" ? (
                              <ActualizarMetodoPagoProvider
                                onFinishUpdate={onFinishUpdate}
                                onCancel={() => setState({ viewSelected: null })}
                              />
                            ) : (
                              <>
                                <div className='flex flex-col xs:flex-row gap-6 items-center justify-between text-center xs:text-left'>
                                  <div>
                                    <p className='font-golos-semibold text-Crayola text-2xl'>Mi Suscripción</p>
                                    <p>Esta es tu suscripción actual con folio #{state.suscripcion_seleccionada?.invoiceCode}.</p>
                                  </div>
                                  {((state.suscripcion_seleccionada?.isInternal) || ["paid", "trialing"].includes(state.suscripcion_seleccionada?.status)) && (
                                    <div>
                                      <ButtonPrimary
                                        text="Cambiar tu suscripción"
                                        loading={state.saving.change_suscription}
                                        onClick={() => setState({ viewSelected: "actualizarSuscripcion" })}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className='flex flex-col space-y-8'>
                                  <div className='inline-flex flex-col sm:flex-row justify-center rounded-xl'>
                                    <div className='md:min-h-[10rem] bg-Crayola px-4 py-4 md:px-7 md:py-5 lg:px-10 lg:py-8 rounded-t-xl sm:rounded-tr-none sm:rounded-l-xl text-center lg:text-left lg:min-w-[280px]'>
                                      <p className='font-golos text-white text-sm mb-4'>{state.suscripcion_seleccionada?.tipo_programa || "Programa de entrenamiento"}:</p>
                                      <div className='mb-0'>
                                        <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-white uppercase !leading-7'>{state.suscripcion_seleccionada?.nombre}</p>
                                        <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-white uppercase !leading-7'>{state.suscripcion_seleccionada?.plan}</p>
                                      </div>
                                    </div>
                                    <div className='bg-white relative rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl w-full'>
                                      <Image
                                        src={getPortadaPrograma(state.suscripcion_seleccionada?.code)}
                                        imageClassName="bg-black min-h-[10rem] h-full w-full object-cover rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl"
                                        className='bg-black sm:absolute h-full w-full rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl'
                                      />
                                    </div>
                                  </div>
                                  <div className="bg-Magnolia grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[1fr] lg:grid-flow-col lg:auto-cols-fr gap-10 items-center rounded-xl px-5 py-6">
                                    {state.suscripcion_seleccionada?.isInternal ? (
                                      <>
                                        <div className='mx-auto lg:m-none text-center lg:text-left'>
                                          <p className='font-golos-semibold text-md leading-5'>Fecha de inicio:</p>
                                          <p className='capitalize text-sm'>{state?.suscripcion_seleccionada?.dateExpirationStart ? dayjs(state?.suscripcion_seleccionada?.dateExpirationStart).format("MMMM DD, YYYY") : ((state?.suscripcion_seleccionada?.createdAt && dayjs(state?.suscripcion_seleccionada?.createdAt).format("MMMM DD, YYYY")) || "Desconocido")}</p>
                                        </div>
                                        <div className='mx-auto text-center lg:text-left'>
                                          <p className='font-golos-semibold text-md leading-5'>{(state?.suscripcion_seleccionada?.status === "expired" || state?.suscripcion_seleccionada?.status_to_expire === "Expirada") ? "Suscripción finalizada" : "Expira en"}:</p>
                                          <p className='capitalize text-sm'>{state?.suscripcion_seleccionada?.dateExpirationEnd ? dayjs(state?.suscripcion_seleccionada?.dateExpirationEnd).format("MMMM DD, YYYY") : "Desconocido"}</p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className='mx-auto lg:m-none text-center lg:text-left'>
                                          <p className='font-golos-semibold text-md leading-5'>Fecha de inicio:</p>
                                          <p className='capitalize text-sm'>{state?.suscripcion_seleccionada?.fecha_inicio ? dayjs(state?.suscripcion_seleccionada?.fecha_inicio).format("MMMM DD, YYYY") : "Desconocido"}</p>
                                        </div>
                                        {(["cancelled", "expired", "rejected", "paused"].includes(state.suscripcion_seleccionada?.status) === false) ? (
                                          <div className='mx-auto text-center lg:text-left'>
                                            <p className='font-golos-semibold text-md leading-5'>Próximo cobro:</p>
                                            {(state?.suscripcion_seleccionada?.isTrial) && (
                                              <p className='font-golos text-xs text-gray-600 leading-5'>{`(Prueba de ${dayjs(state?.suscripcion_seleccionada?.fecha_cobro).diff(dayjs(state?.suscripcion_seleccionada?.fecha_inicio), 'day') || 0} días)`}</p>
                                            )}
                                            <p className='capitalize text-sm'>{state?.suscripcion_seleccionada?.fecha_cobro ? dayjs(state?.suscripcion_seleccionada?.fecha_cobro).format("MMMM DD, YYYY") : "No disponible"}</p>
                                          </div>
                                        ) : (
                                          <div className='mx-auto text-center lg:text-left'>
                                            <p className='font-golos-semibold text-md leading-5'>{(state?.suscripcion_seleccionada?.status === "expired" || state?.suscripcion_seleccionada?.status_to_expire === "Expirada") ? "Suscripción finalizada" : "Expira en"}:</p>
                                            {(state.suscripcion_seleccionada?.isTrial) && (
                                              <p className='font-golos text-xs text-gray-600 leading-5'>{`(Prueba de ${dayjs(state?.suscripcion_seleccionada?.fecha_expiracion || state?.suscripcion_seleccionada?.fecha_cobro).diff(dayjs(state?.suscripcion_seleccionada?.fecha_inicio), 'day') || 0} días)`}</p>
                                            )}
                                            <p className='capitalize text-sm'>{(state?.suscripcion_seleccionada?.fecha_expiracion || state?.suscripcion_seleccionada?.fecha_cobro) ? dayjs((state?.suscripcion_seleccionada?.fecha_expiracion || state?.suscripcion_seleccionada?.fecha_cobro)).format("MMMM DD, YYYY") : "Desconocido"}</p>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {state.suscripcion_seleccionada?.isInternal ? (
                                      <div className='mx-auto text-center lg:text-left break-words w-full'>
                                        <p className='font-golos-semibold text-md leading-5'>Administrado por</p>
                                        <p className='lg:truncate text-sm'>JimeFitMom</p>
                                      </div>
                                    ) : (
                                      <div className='mx-auto text-center lg:text-left break-words w-full'>
                                        <p className='font-golos-semibold text-md leading-5'>{state?.suscripcion_seleccionada?.card ? `VISA ${state?.suscripcion_seleccionada?.card?.last_4}` : "Vía PayPal"}</p>
                                        <p className='lg:truncate text-sm'>{state?.suscripcion_seleccionada?.card ? `Exp ${getNumericZero(state?.suscripcion_seleccionada?.card?.exp_month)} / ${state?.suscripcion_seleccionada?.card?.exp_year}` : state?.suscripcion_seleccionada?.paypal?.email}</p>
                                      </div>
                                    )}
                                    <div className='text-center lg:text-right'>
                                      {(
                                        ["paid", "trialing", "hold"].includes(state.suscripcion_seleccionada?.status) === true
                                      ) ? (
                                        <p className='font-golos text-sm'>Recuerda que puedes cancelar cuando quieras</p>
                                      ) : (
                                        <p className='font-golos-medium text-sm text-red-600'>{(state?.suscripcion_seleccionada?.status_to_expire === "Expirada" && ((state.suscripcion_seleccionada?.status === 'expired'))) ? "La suscripción actual ha finalizado" : (state?.suscripcion_seleccionada?.status_to_expire === "Por expirar" ? "La suscripción actual será cancelada al finalizar el periodo" : "La suscripción actual ha sido cancelada")}</p>
                                      )}
                                    </div>
                                  </div>
                                  {(state.suscripcion_seleccionada || state.suscripcion_seleccionada?.isInternal) && (
                                    <div className='flex flex-col md:flex-row items-center justify-center gap-6 w-full'>
                                      {((state.suscripcion_seleccionada || state.suscripcion_seleccionada?.isInternal) && ["paid", "trialing"].includes(state.suscripcion_seleccionada?.status)) ? (
                                        <div className='flex flex-wrap gap-4 justify-center pt-4'>
                                          <ButtonPrimary
                                            text="Actualizar método de pago"
                                            loading={state.saving.change_suscription}
                                            classNameButton={"!px-6"}
                                            onClick={() => setState({ viewSelected: "actualizarMetodoPago" })}
                                          />
                                          <ButtonPrimary
                                            text="Cancelar suscripción"
                                            loading={state.saving.cancel_suscription}
                                            classNameContainer="!border-DeepKoamaru"
                                            classNameButton={"!px-6 !bg-DeepKoamaru hover:!bg-[#211155]"}
                                            onClick={() => setState({ confirmDialogVisible: true })}
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {(
                                            ["hold", "cancelled", "canceled", "paused", "expired", "rejected"].includes(state.suscripcion_seleccionada?.status)
                                          ) && (
                                              <div className='flex gap-4 justify-center pt-4'>
                                                <ButtonPrimary
                                                  text="Reanudar suscripción"
                                                  loading={state.saving.cancel_suscription}
                                                  classNameContainer="!border-DeepKoamaru"
                                                  classNameButton={"!px-6 !bg-DeepKoamaru hover:!bg-[#211155]"}
                                                  onClick={reanudar_suscripcion}
                                                />
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

const ActualizarSuscripcion = ({ suscripcion_seleccionada, idSuscripcion, onCancel, onFinishUpdate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    cicloSeleccionado: null,
    programaSeleccionado: null,
    programas: [],
  });

  useEffect(() => {
    getProgramas().then(response => {
      setState({ loading: false, programas: response?.data });
    });

    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (state.programas?.length > 0) {
      setState({
        programaSeleccionado: state.programas?.find(item => item?.code === suscripcion_seleccionada?.code),
        cicloSeleccionado: `${suscripcion_seleccionada?.code}-${suscripcion_seleccionada?.plan}`
      });
    }

    // eslint-disable-next-line 
  }, [state.programas, suscripcion_seleccionada]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const crearSuscripcionPaypal = useCallback(async () => {
    const cycleType = state.cicloSeleccionado && (state.cicloSeleccionado?.indexOf("Mensual") !== -1 ? "mensual" : "semestral");
    setState({ sending: true });
    const start_time = ["expired", "paused", "rejected", "cancelled"].includes(suscripcion_seleccionada?.status) ? (suscripcion_seleccionada?.subscriptionCancelDate || suscripcion_seleccionada?.nextBillingDate) : suscripcion_seleccionada?.nextBillingDate;
    const _subscription = await crear_suscripcion_paypal(null, {
      plan_id: cycleType === "mensual" ? state?.programaSeleccionado?.ProgramsDetail?.pricePaypalMonthID : state?.programaSeleccionado?.ProgramsDetail?.pricePaypalBiannualID,
      start_time: start_time,
      custom_id: "generate",
      query_params: {
        cycleType: cycleType,
        userID: user?.userID,
        programID: state.programaSeleccionado?.id,
      }
    });
    setState({ sending: false });
    const subscriptionUrl = (_subscription?.data?.links?.find(item => item?.rel === "approve"))?.href;

    window.localStorage.setItem("subscriptionID-paypal", JSON.stringify({
      id: _subscription?.data?.id,
      programID: state.programaSeleccionado?.id,
      cycleType: cycleType,
      invoiceCode: _subscription?.data?.custom_id,
    }));

    window.location = subscriptionUrl;
  }, [user?.userID, state, suscripcion_seleccionada, setState]);

  const onUpdateSubscription = useCallback(async () => {
    const cycleType = state.cicloSeleccionado && (state.cicloSeleccionado?.indexOf("Mensual") !== -1 ? "mensual" : "semestral");

    if (isEmpty(cycleType)) {
      return message(null, "Selecciona el tipo de ciclo para continuar");
    }

    if (!isEmpty(suscripcion_seleccionada?.nextBillingDate)) {
      const isSameDay = (startTime) => {
        const currentDate = dayjs_custom(new Date(), { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        const startTimeDate = dayjs_custom(startTime, { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        return currentDate.isSame(startTimeDate, 'day');
      };
      if (isSameDay(suscripcion_seleccionada?.nextBillingDate)) return message(null, "No se permiten realizar modificaciones en la fecha de facturación.");
    }

    if (suscripcion_seleccionada?.isInternal) {
      dispatch(setSubscription({
        data: {
          id: state.programaSeleccionado?.id,
          tipo_programa: "Programa de entrenamiento",
          code: state.programaSeleccionado?.code,
          nombre: state.programaSeleccionado?.name,
          plan: cycleType,
          precio: cycleType === "mensual" ? state.programaSeleccionado?.cost_month : state.programaSeleccionado?.cost_biannual,
        }
      }));
      navigate("/carrito");
    } else {
      try {
        if (suscripcion_seleccionada?.paymentType === "PayPal") {
          await crearSuscripcionPaypal()
        } else {
          setState({ sending: true });
          const response = await updateSubscription({
            userID: user?.userID,
            programID: state.programaSeleccionado?.id,
            id: idSuscripcion,
            cycleType: cycleType,
          });

          setState({ sending: false });
          if (response?.status === true) {
            message('Suscripción actualizada', '¡La suscripción se ha actualizado exitosamente!');
            await getPerfil({ id: user?.id, email: user?.email })
              .then(response => {
                if (response?.status === true && response?.data) {
                  dispatch(setUser(response?.data))
                  if (onFinishUpdate) onFinishUpdate(response?.data);
                }
              })
              .catch(error => {
                if (onFinishUpdate) onFinishUpdate();
              })
          } else {
            message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la suscripción. Por favor, inténtalo de nuevo más tarde.');
          }
        }
      } catch (error) {
        setState({ sending: false });
        message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar la suscripción. Por favor, inténtalo de nuevo más tarde.');
      }
    }
  }, [idSuscripcion, user, suscripcion_seleccionada?.isInternal, suscripcion_seleccionada?.nextBillingDate, state.programaSeleccionado, state.cicloSeleccionado, suscripcion_seleccionada?.paymentType, setState, dispatch, navigate, onFinishUpdate, crearSuscripcionPaypal]);

  return (
    <div className='animate__animated animate__fadeIn'>
      {state.loading ? (
        <Loading />
      ) : (
        <div className='flex flex-col gap-8'>
          <div>
            <h1 className='font-golos-semibold text-Crayola text-2xl'>Actualiza tu suscripción</h1>
            <p className='font-golos text-DarkJungleGreen text-sm'>Al hacer el cambio se tomará en cuenta el tiempo restante de la suscripción actual y se recorrerá proporcionalmente en la fecha de cobro de la nueva suscripción solo si el plan actual está activo y no cancelado.</p>
          </div>
          <div className='flex flex-wrap items-center justify-center gap-6'>
            {state?.programas?.length > 0 && (
              <div className='inline-flex flex-col md:flex-row flex-wrap justify-center gap-4'>
                {Array.isArray(state?.programas) && state.programas?.map((item, index) => (
                  <div key={`programa-key-${item?.id || index}`} className='relative bg-white shadow-md shadow-DarkJungleGreen/10 rounded-xl text-center'>
                    <div className='bg-Orchid py-3 px-4 mb-6 rounded-t-xl'>
                      <p className='font-golos-semibold text-md md:text-lg text-center text-white mb-0'>{item?.name}</p>
                    </div>
                    <div className='pb-6 px-4 select-none'>
                      <div className='flex justify-center items-center px-5 mt-4'>
                        <div
                          className={`${state.cicloSeleccionado === `${item?.code}-Mensual` ? "bg-Orchid border border-Orchid text-white font-golos-medium" : "bg-white border border-gray-200 text-Crayola"} py-1.5 px-2 pr-3 rounded-l-md c-pointer`}
                          onClick={() => !state.sending && setState({ programaSeleccionado: item, cicloSeleccionado: `${item?.code}-Mensual` })}
                        >
                          <p className='text-sm'>Mensual</p>
                        </div>
                        <div
                          className={`${state.cicloSeleccionado === `${item?.code}-Semestral` ? "bg-Orchid border border-Orchid text-white font-golos-medium" : "bg-white border border-gray-200 text-Crayola"} py-1.5 px-2 -ml-1 rounded-r-md c-pointer`}
                          onClick={() => !state.sending && setState({ programaSeleccionado: item, cicloSeleccionado: `${item?.code}-Semestral` })}
                        >
                          <p className='text-sm'>Semestral</p>
                        </div>
                      </div>
                      <p className='font-golos-semibold text-xl md:text-2xl text-Crayola mt-4'>
                        <span>${item?.code === state?.programaSeleccionado?.code ? parseFloat(state.cicloSeleccionado === `${item?.code}-Mensual` ? item?.cost_month : item?.cost_biannual)?.toFixed(2) : (item?.cost_month || "0.00")}</span>
                        <span className='font-golos-medium text-lg lg:text-xl'>USD</span>
                      </p>
                      {item?.code === state?.programaSeleccionado?.code && (
                        <div className='mt-4'>
                          <ButtonPrimary
                            text={(item?.code === suscripcion_seleccionada?.code && `${item?.code}-${suscripcion_seleccionada?.plan}` === state.cicloSeleccionado) ? "Seleccionado" : "Seleccionar"}
                            withIcon={(item?.code === suscripcion_seleccionada?.code && `${item?.code}-${suscripcion_seleccionada?.plan}` === state.cicloSeleccionado) ? false : true}
                            disabled={(item?.code === suscripcion_seleccionada?.code && `${item?.code}-${suscripcion_seleccionada?.plan}` === state.cicloSeleccionado) ? true : false}
                            loading={(state.sending) ? true : false}
                            classNameContainer="flex justify-center"
                            onClick={() => onUpdateSubscription(item)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <ButtonCustom
              text={"REGRESAR"}
              withIconBack={true}
              iconBack={{ fill: "#fff" }}
              classNameContainer="flex justify-center"
              classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
              onClick={onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const ActualizarMetodoPagoProvider = ({ onCancel, onFinishUpdate }) => {
  const { user } = useSelector(state => state.app.auth);
  const [stateMetodoPago, setStateMetodoPago] = useCustomState({
    tabSelected: "card",
  });
  const [state, setState] = useCustomState({
    setupIntentId: null,
    optionsStripe: {
      clientSecret: null,
      paymentMethodCreation: "manual",
    }
  });
  const dispatch = useDispatch();

  useEffect(() => {
    updateOptionsStripe({ user });

    // eslint-disable-next-line
  }, [user?.userID]);

  const updateOptionsStripe = useCallback(({ user: userLocal, onlySetupIntent, setupIntentId }) => {
    const _user = userLocal || user;
    if (onlySetupIntent && setupIntentId) {
      return setState({ setupIntentId: setupIntentId });
    }
    if (!isEmpty(_user?.userID)) {
      getClienteSecreto(_user?.userID).then(response => {
        if (!onlySetupIntent) setState({ clientSecret: response?.data?.client_secret }, 'optionsStripe');
        setState({ setupIntentId: response?.data?.id });
      });
    }
  }, [user, setState]);

  const _onFinishUpdate = useCallback(async () => {
    await getPerfil({ id: user?.id, email: user?.email })
      .then(response => {
        if (response?.status === true && response?.data) {
          dispatch(setUser(response?.data))
          if (onFinishUpdate) onFinishUpdate(response?.data);
        }
      })
      .catch(error => {
        if (onFinishUpdate) onFinishUpdate();
      })
  }, [user?.id, user?.email, onFinishUpdate, dispatch]);

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='flex flex-col gap-8'>
        <div className='flex items-center justify-between flex-col md:flex-row gap-6'>
          <div>
            <h1 className='font-golos-semibold text-Crayola text-2xl'>Actualiza tu método de pago</h1>
            <p>Selecciona un método para actualizar tu pago:</p>
          </div>
          <div className='col-span-1 flex flex-wrap items-center gap-3'>
            <button
              type="button"
              className={`${stateMetodoPago?.tabSelected === 'card' ? "font-golos-medium bg-Crayola text-white" : "text-Crayola"} border border-Crayola text-sm px-4 py-3 rounded-lg active:scale-95 transition-all`}
              onClick={() => setStateMetodoPago({ tabSelected: "card" })}
            >
              Débito / Crédito
            </button>
            <button
              type="button"
              className={`${stateMetodoPago?.tabSelected === 'paypal' ? "font-golos-medium bg-Crayola text-white" : "text-Crayola"} border border-Crayola text-sm px-5 py-3 rounded-lg active:scale-95 transition-all`}
              onClick={() => setStateMetodoPago({ tabSelected: "paypal" })}
            >
              PayPal
            </button>
          </div>
        </div>
        <div className='mt-2'>
          {(stateMetodoPago.tabSelected === "card") && (
            <>
              {(isEmpty(state.optionsStripe?.clientSecret) || isEmpty(state.setupIntentId) || !stripePromise) ? (
                <Loading />
              ) : (
                <Elements key={state.optionsStripe?.clientSecret} stripe={stripePromise} options={state.optionsStripe}>
                  <ActualizarMetodoPago
                    methodSelected={stateMetodoPago.tabSelected}
                    onCancel={onCancel}
                    _onFinishUpdate={_onFinishUpdate}
                    updateOptionsStripe={updateOptionsStripe}
                  />
                </Elements>
              )}
            </>
          )}
          {(stateMetodoPago.tabSelected === "paypal" && apiKeysRemote?.paypal) && (
            <PayPalScriptProvider
              options={{
                clientId: apiKeysRemote.paypal,
                intent: "subscription",
                disableFunding: "card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo",
                vault: true,
                locale: "es_MX"
              }}
            >
              <ActualizarMetodoPago
                methodSelected={stateMetodoPago.tabSelected}
                onCancel={onCancel}
                _onFinishUpdate={_onFinishUpdate}
              />
            </PayPalScriptProvider>
          )}
        </div>
      </div>
    </div>
  );
}

const StripeForm = ({ user, onCancel, _onFinishUpdate, updateOptionsStripe }) => {
  const [state, setState] = useCustomState({
    sending: {
      pay: false,
    },
    metodo_pago: {
      codigo_postal: "",
    }
  });
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const handlePaymentError = useCallback((title, _message) => {
    setState({ pay: false }, 'sending');
    message(title, _message);
  }, [setState]);

  const handleCheckoutStripe = useCallback(async (event) => {
    event.preventDefault();
    if (isEmpty(user?.subscription?.id) || isEmpty(user?.userID)) {
      return message("Información incompleta", "Se encontro información requerida que no se esta enviando");
    }
    if (isEmpty(state.metodo_pago.codigo_postal)) {
      return message("Información incompleta", "El código postal es requerido para continuar");
    }

    if (!isEmpty(user?.subscription?.nextBillingDate)) {
      const isSameDay = (startTime) => {
        const currentDate = dayjs_custom(new Date(), { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        const startTimeDate = dayjs_custom(startTime, { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        return currentDate.isSame(startTimeDate, 'day');
      };
      if (isSameDay(user?.subscription?.nextBillingDate)) return message(null, "No se permiten realizar modificaciones en la fecha de facturación.");
    }

    try {
      setState({ pay: true }, 'sending');

      elements.submit();
      const { error: errorCreatePayment, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: user?.fullName || user?.firstName,
            address: {
              country: "MX",
              postal_code: state.metodo_pago.codigo_postal
            }
          },
        },
      });

      if (errorCreatePayment) {
        setState({ pay: false }, 'sending');
        return message(null, errorCreatePayment?.message || 'Lo sentimos, ocurrió un error al crear el método de pago. Por favor, inténtalo de nuevo más tarde.');
      } else {
        const _verificar_tarjeta = await verificar_tarjeta({
          userID: user?.userID,
          paymentMethodId: paymentMethod?.id,
        });

        if (_verificar_tarjeta?.status === "requires_action") {
          const client_secret = _verificar_tarjeta?.data?.paymentIntent?.client_secret;
          const paymentIntentVerified = await stripe.handleNextAction({ clientSecret: client_secret });

          if (paymentIntentVerified.error) {
            // const getPaymentIntent = await stripe.retrievePaymentIntent(client_secret);
            try {
              await cancelar_verificar_tarjeta({ paymentIntentId: _verificar_tarjeta?.data?.paymentIntent?.id });
            } catch (error) {
              console.log("🚀 ~ handleCheckoutStripe ~ error:", error);
            }

            return handlePaymentError(null, paymentIntentVerified.error?.message);
          }
        }

        try {
          await cancelar_verificar_tarjeta({ paymentIntentId: _verificar_tarjeta?.data?.paymentIntent?.id });
        } catch (error) { }

        const _actualizar_suscripcion = await actualizar_metodo_pago({
          id: user?.subscription?.id,
          userID: user?.userID,
          paymentMethodId: paymentMethod?.id,
          cardBrand: paymentMethod?.card?.brand,
          cardLast4: paymentMethod?.card?.last4,
          expirationMonth: paymentMethod?.card?.exp_month,
          expirationYear: paymentMethod?.card?.exp_year,
          methodPayment: "stripe",
          // setupIntentId: state?.setupIntentId,
        });
        if (["active", "trialing", "paid", "APPROVED", 'incomplete'].includes(_actualizar_suscripcion?.status || _actualizar_suscripcion?.data?.status)) {
          message("Suscripción actualizada", "¡La suscripción se ha actualizado exitosamente!");
          _onFinishUpdate();
        }
        setState({ pay: false }, 'sending');
      }
    } catch (error) {
      console.log("🚀 ~ handleCheckoutStripe ~ error:", error);
      setState({ pay: false }, 'sending');
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al realizar el pago. Por favor, inténtalo de nuevo más tarde.');
      if (parseErrorMessage(error)?.indexOf("this SetupIntent because it has been canceled") !== -1) {
        const errors = error.response.data.errors;
        if (!isEmpty(errors?.setupIntentId)) updateOptionsStripe({ onlySetupIntent: true, setupIntentId: errors?.setupIntentId });
      }
      if (parseErrorMessage(error)?.indexOf("cannot update this SetupIntent because it has already succeeded") !== -1) {
        updateOptionsStripe({ onlySetupIntent: true });
      }
    }
  }, [elements, user, state.metodo_pago, setState, _onFinishUpdate, handlePaymentError, stripe, updateOptionsStripe]);

  return (
    <form className='space-y-4 animate__animated animate__fadeIn' onSubmit={(event) => handleCheckoutStripe(event)}>
      <PaymentElement
        id="payment-element"
        options={{
          business: {
            name: "JimeFitMom"
          },
          layout: {
            type: 'tabs',
          },
          fields: {
            billingDetails: {
              name: "never",
              address: {
                country: 'never',
                postalCode: "never"
              }
            }
          },
          paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'oxxo', 'paypal', 'blik', 'klarna', 'zip']
        }}
      />
      <input
        name="codigo_postal"
        type="number"
        placeholder="Código postal *"
        className="bg-white !outline-none border border-[#e6e6e6] focus-within:border-indigo-500 focus-within:ring-indigo-500 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 w-full transition-all [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        value={state.metodo_pago.codigo_postal}
        onChange={(event) => setState({ codigo_postal: event.target.value }, "metodo_pago")}
      />
      <div className='flex flex-wrap justify-center gap-3 pt-8'>
        <ButtonPrimary
          text={"Regresar"}
          // withIconBack={true}
          classNameContainer="!border-DeepKoamaru"
          classNameButton={"!py-3 !px-14 !bg-DeepKoamaru hover:!bg-[#211155]"}
          type="button"
          onClick={onCancel}
        />
        <ButtonPrimary
          text={"Actualizar"}
          classNameButton={"!py-3 !px-14"}
          type="button"
          loading={state.sending.pay}
          onClick={(event) => handleCheckoutStripe(event, user, state, setState, navigate, elements)}
        />
      </div>
    </form>
  );
}

const PayPalReducer = ({ children }) => {
  const [{ isPending }] = usePayPalScriptReducer();

  return children({ isPending });
}

const PayPalForm = ({ user, _onFinishUpdate }) => {
  const invoiceCode = useRef(generarCodigoFactura());

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const createSubscriptionPaypal = useCallback(async () => {
    if (!isEmpty(user?.subscription?.nextBillingDate)) {
      const isSameDay = (startTime) => {
        const currentDate = dayjs_custom(new Date(), { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        const startTimeDate = dayjs_custom(startTime, { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        return currentDate.isSame(startTimeDate, 'day');
      };
      if (isSameDay(user?.subscription?.nextBillingDate)) return message(null, "No se permiten realizar modificaciones en la fecha de facturación.");
    }

    try {
      const subscription = user?.subscription;
      const cycleType = subscription?.typePlan;
      const ProgramsDetail = subscription?.Program?.ProgramsDetail;
      const start_time = ["expired", "paused", "rejected", "cancelled"].includes(subscription?.status) ? (subscription?.subscriptionCancelDate || subscription?.nextBillingDate) : subscription?.nextBillingDate;

      const _subscription = await crear_suscripcion_paypal(null, {
        plan_id: cycleType === "Mensual" ? ProgramsDetail?.pricePaypalMonthID : ProgramsDetail?.pricePaypalBiannualID,
        start_time: start_time,
        custom_id: invoiceCode.current,
        query_params: {
          cycleType: cycleType,
          userID: user?.userID,
          programID: user?.programID,
        }
      });

      return _subscription?.data?.id;
    } catch (error) {
      const _error = parseErrorMessage(error, true);
      message(null, _error);
      return null;
    }
  }, [user]);

  const onApprovePaypal = useCallback(async (_data) => {
    if (_data?.subscriptionID) {
      try {
        const _actualizar_suscripcion = await actualizar_metodo_pago({
          id: user?.subscription?.id,
          userID: user?.userID,
          methodPayment: "paypal",
          invoiceCode: invoiceCode.current,
          subscriptionID: _data?.subscriptionID,
          paymentTransaction: _data?.orderID,
          paypalToken: _data?.facilitatorAccessToken,
        });

        if (_actualizar_suscripcion.status) {
          message("Suscripción actualizada", "¡La suscripción se ha actualizado exitosamente!");
          _onFinishUpdate();
        } else {
          message(null, "Error al actualizar el método de pago");
        }
      } catch (error) {
        const _error = parseErrorMessage(error, false) || "Error al actualizar el método de pago";
        message(null, _error);
        // setStateInternal({ pay: false }, 'sending');
      }
    }
  }, [user?.subscription?.id, user?.userID, _onFinishUpdate]);

  return (
    <PayPalReducer>
      {({ isPending }) => (
        <div className='flex flex-col items-center justify-center gap-6 animate__animated animate__fadeIn'>
          <div className='inline-flex flex-col gap-2'>
            {(isPending) ? <Loading /> : (
              <>
                <PayPalButtons
                  style={{
                    shape: 'rect',
                    layout: 'vertical', //default value. Can be changed to horizontal
                  }}
                  createSubscription={(data) => createSubscriptionPaypal(data, user)}
                  onApprove={(data, actions) => onApprovePaypal(data, actions)}
                  onCancel={(data, actions) => actions.redirect()}
                  onError={() => { }}
                  forceReRender={[user]}
                />
                <p className='text-xs text-gray-500'>Elige PayPal para mayor comodidad y seguridad. Al hacerlo, permites que JimeFitMom realice futuros cargos en tu tarjeta según lo acordado.</p>
              </>
            )}
          </div>
        </div>
      )}
    </PayPalReducer>
  );
}

const ActualizarMetodoPago = ({ methodSelected, onCancel, _onFinishUpdate, updateOptionsStripe }) => {
  const { user } = useSelector(state => state.app.auth);

  return (
    <>
      {methodSelected === "card" && (
        <StripeForm user={user} onCancel={onCancel} _onFinishUpdate={_onFinishUpdate} updateOptionsStripe={updateOptionsStripe} />
      )}
      {methodSelected === "paypal" && (
        <PayPalForm user={user} onCancel={onCancel} _onFinishUpdate={_onFinishUpdate} />
      )}
    </>
  );
}

const App = (props) => {
  const [apiKeys, setApiKeys] = useCustomState(null);

  useEffect(() => {
    getApiKeys().then(async apiKeys => {
      apiKeysRemote = apiKeys;
      stripePromise = loadStripe(apiKeys.stripe);
      setApiKeys(apiKeys);
    });
    // eslint-disable-next-line
  }, []);

  if (!apiKeys) return <Loading containerClassName={"flex-1 h-full py-10"} />;

  return (
    <MiSuscripcion apiKeysRemote={apiKeys} {...props} />
  );
};

export default App;