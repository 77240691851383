import { memo } from "react";

const RazonesEconomicas = () => (
  <div className="bg-Crayola rounded-lg py-5 px-5">
    <div className="flex items-center w-full">
      <div className="flex flex-col flex-1 w-full space-y-3">
        <p className="text-white text-[11px] font-golos leading-[14px] text-left">
          Nuestra recomendación:
        </p>
        <p className="text-white text-[16px] font-golos-semibold leading-[23px] text-left">
          Queremos que sigas disfrutando del programa.
          <p>Obtén <span className="text-[18px] font-golos-bold leading-[25px]">10% off</span> al continuar con tu suscripción.</p>
        </p>
      </div>
    </div>
  </div>
);

export default memo(RazonesEconomicas);